import bMainPage from '../images/bitwault/home.png'
import bEditPage from '../images/bitwault/edit.png'
import bListPage from '../images/bitwault/list.png'
import bMembersPage from '../images/bitwault/members.png'

import gProfilePage from '../images/gofit/profilePage.png'
import gProfileCollectionPage from '../images/gofit/profileCollections.png'
import gPostPage from '../images/gofit/postPage.png'
import gCreatePage from '../images/gofit/createPost.png'
import gCreateInvite from '../images/gofit/createInvite.png'
import gInviteDetails from '../images/gofit/invitaionDetailPage.png'

function Project(id, name, type, author, desc, tech, link, github, demo) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.author = author;
    this.desc = desc;
    this.tech = tech;
    this.link = link;
    this.github = github;
    this.demo = demo;
}

function PicAndDesc(id, desc, image) {
    this.id = id;
    this.desc = desc;
    this.image = image;
}

let gofitDemo = [
    new PicAndDesc(1, "This is the user's profile page. Users can edit their avatar and profile background.\nUnread comments and new invitations will be displayed on the top right corner", gProfilePage),
    new PicAndDesc(2, "Each user can create many collections to store posts they like into different categroies.", gProfileCollectionPage),
    new PicAndDesc(3, "Of course user are able to create a post, they can enter their rough location, the backend api will pickup the info and use Google geoLocation api to get the full adress.", gCreatePage),
    new PicAndDesc(4, "In this page, post details will be displayed alone with location the post was created, likes count, times that the post added to collections.\n User can leave comments, reply to comments and even like a comment", gPostPage),
    new PicAndDesc(5, "If you like a person's post content, why not invite him/her to workout together. Simply send a message with gym location and time.", gCreateInvite),
    new PicAndDesc(6, "Gym location (used google map api), time will be displayed in this page. After a meetup, you can leave reviews to that meetup. Others will see it on that person's profile page.", gInviteDetails),
]

let bitWaultDemo = [
    new PicAndDesc(1, "Websites are categorized into many types.", bMainPage),
    new PicAndDesc(2, "List of username/email and password, have quick access to copy to clipboard.", bListPage),
    new PicAndDesc(3, "Can edit password easily.", bEditPage),
    new PicAndDesc(4, "If you are the admin of a organization, you have permit to add or delete a member to manage his/her access to all your passwords.", bMembersPage),
]

const gofit = new Project(1, 'Gofit', 'Social', 'Bowen Jiang',
    'A social app for people who wants to find gym buddy and share their workout moments.',
    'Built with MongoDb, Express, React, Node. Used google GeoLocation api, AWS s3 for cloud image storage.',
    'https://gofit-9bad6.web.app/home',
    ['https://github.com/bopepsi/App-api', 'https://github.com/bopepsi/App-react'], gofitDemo);

const bitWault = new Project(2, 'BitWault', 'Office', 'Bowen Jiang',
    'A password storage app for organizations to share group passwords to their employess.',
    'Build with Node, EJS, ElephantSQL, created unique functions to generate random password based on users\' choice.',
    'https://bitwault.herokuapp.com', ['https://github.com/bopepsi/midterm'], bitWaultDemo);


export const projects = [
    gofit,
    bitWault
]