import './App.css';
import Header from './components/shared/Header';
import Footer from './components/shared/Footer';
import { Route, Switch } from 'react-router-dom';
import Project from './pages/Project';
import Main from './pages/Main';
import AllProjects from './pages/AllProjects';
import { useCallback, useEffect, useRef, useState } from 'react';
import upArrow from './images/up-arrow.png'

function App() {

  const [scroll, setScroll] = useState(false);
  const [toTop, setToTop] = useState(false);
  const sRef = useRef();
  const hRef = useRef();

  const scrollHandler = useCallback(() => {
    let t = sRef.current.scrollTop;
    setScroll(t);
  }, [])

  const scrollToTop = useCallback(() => {
    setToTop(p => !p);
  }, [])

  useEffect(() => {
    hRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [toTop])

  return (
    <div ref={sRef} className="App" onScroll={scrollHandler}>
      <div className='wrapper'>

        <div ref={hRef} id="nothing"></div>
        <Header />

        <Switch>

          <Route path={'/'} exact>
            <Main />
          </Route>

          <Route path={'/projects'} exact>
            <AllProjects />
          </Route>

          <Route path={'/project/:project_name'}>
            <Project />
          </Route>

        </Switch>

        <Footer />

        {(scroll > 263) && <img id='back-to-top' src={upArrow} alt="back-to-top" onClick={scrollToTop} />}

      </div>
    </div>
  );
}

export default App;
