import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Header.module.css'
import Particles from 'particlesjs'

function Header() {

  return (
    <header className={`${styles.header}`}>
      <Link className={`${styles.link}`} to={'/'} >HOME</Link>
      <div>
        <a className={`${styles.link} ${styles.a} `} href="bowen_resume.pdf" download="Bowen_Resume">Resume</a>
        <Link to={'/projects'} className={`${styles.link} ${styles.a}`}>Projects</Link>
        <a className={`${styles.link} ${styles.a} `} href="mailto: bopepsi@gmail.com" >Contact me</a>
        <a className={`${styles.last_a} `} href={`https://bopepsi-denoblog.deno.dev/`} target={'_blank'}>Blog</a>
      </div>
    </header>
  )
}

export default Header