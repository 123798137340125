import React from "react";
import styles from "./DescItem.module.css"

function DescItem(props) {

    const { id, desc, image } = props;

    return (
        <li>
            <p className={`${styles.text}`}><b> {`${id}. `}</b>{desc}</p>
            <img src={image} className={`${styles.image}`} alt="demo"></img>
        </li >
    )
}

export default React.memo(DescItem)
