import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Footer.module.css'

function Footer() {
  return (
    <footer className={`${styles.footer}`}>
      <p>© 2022 Bowen, Powered by React</p>
    </footer>
  )
}

export default Footer