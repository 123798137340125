import React from 'react'
import ProjectsList from '../components/contents/ProjectsList'
import styles from './AllProjects.module.css'

function AllProjects() {
    return (
        <section className={`${styles.section}`}>
            <ProjectsList />
        </section>

    )
}

export default AllProjects