import React from 'react'
import ProjectItem from './ProjectItem'
import { projects } from '../../data/projectsData'
import styles from './ProjectsList'

function ProjectsList() {
    
    return (
        <ol>
            {projects.map(e => {
                return <ProjectItem key={e.id} author={e.author} desc={e.desc} github={e.github}
                    link={e.link} name={e.name} type={e.social} />
            })}
        </ol>
    )
}

export default ProjectsList