import React from 'react'
import { useParams } from 'react-router-dom'
import DescItem from '../components/contents/DescItem';
import { projects } from '../data/projectsData'
import styles from './Project.module.css'

function Project(props) {

    const params = useParams();
    const projectName = params['project_name'];
    const project = projects.find(e => e['name'] === projectName);
    const demo = project.demo;

    return (
        <div>
            {/* <img className={`${styles.img}`} src={project.image[0]} alt='photo' /> */}
            <h1>
                <a className={`${styles.link}`} href={project.link} target="_blank" rel="noreferrer">
                    {project.name}
                </a>
            </h1>
            <p>
                by: <i>{project.author}</i>
            </p>
            <section className={`${styles.code}`}>
                <p>
                    <a className={`${styles.link}`} href={project.github[0]} target="_blank" rel="noreferrer">
                        {"< Backend Code >"}
                    </a>
                </p>

                {project.github[1] &&
                    <p>
                        <a className={`${styles.link}`} href={project.github[1]} target="_blank" rel="noreferrer">
                            {"< Frontend Code >"}
                        </a>
                    </p>}
            </section>
            <hr />
            <h3>
                {project.desc}
            </h3>
            <p>
                <small>{project.tech}</small>
            </p>
            <hr />
            {demo && demo.map(e => (<DescItem key={e.id} desc={e.desc} image={e.image} id={e.id} />))}
        </div>
    )
}

export default React.memo(Project)