import React from 'react'
import styles from './ProjectItem.module.css';
import Card from '../UI/Card'
import { Link } from 'react-router-dom';

function ProjectItem(props) {


    const { author, desc, github, link, name, type } = props;

    const openWebsite = (event) => {
        event.stopPropagation();
        window.open(link);
    }

    const handleClick = (event) => {
        event.stopPropagation();
    };

    return (

        // <li onClick={openWebsite}>
        <li >
            <Card>
                <h2 className={`${styles.h3}`}>{name}</h2>
                <p className={`${styles.desc}`}>{desc}</p>
                <div className={`${styles.actions}`}>
                    <a href={link} target="_blank" onClick={handleClick}><i>View Website</i></a>
                    <Link to={`/project/${name}`} onClick={handleClick}><i>More Info</i></Link>
                </div>
            </Card>
        </li>

    )
}

export default ProjectItem